#motto {
  font-family: 'Fira Sans', sans-serif;
  font-size: 45px;
  font-weight: 500;
  letter-spacing: 1.5px;
  color: #3da0f8;
}

.mottoBox {
  height: 70vh;
  margin-left: 100px;
  margin-top: -120px;
  margin-bottom: 200px;
  text-align: center;
}

.mottoBox > h1{
  color: #32c7fb;
  font-size: 30px;
  font-weight: 400;
  padding-top: 15vh;
  letter-spacing: 1.5px;
}

.mottoList {
  display: flex;
  flex-direction: row;
  min-height: 300px; /*Evenly space each one*/
}

@media(min-width: 576px) {
  .mottoBox {
    height: 75vh;
  }

  .mottoBox > h1 {
    padding-top: 25vh;
    font-size: 30px;
  }

  #motto {
    font-size: 60px;
  }

}

@media screen and (max-width: 1030px) {
  .mottoList {
    flex-direction: column !important;
    min-width: 0px !important;
  }

  .listFloatWord {
    flex-basis: 0 !important
  }
  .listFloatParagraph {
    margin-top: 0em !important;
    flex: 0 0 400px !important;
  }
}

.homeListColumn {
  margin-left: 8%;
  margin-right: 8%;
}
.listFloatNum {
  flex: 0 0 80px;
  font-size: 65px;
  color: #7f7f7f;
  font-weight: bold;
  font-family: 'Fira Sans', sans-serif;
}

/*CSS for the Number*/
.listFloatWord {
  flex: 0 0 380px;
  font-size: 65px;
  color: #7f7f7f;
  font-weight: bold;
  font-family: 'Fira Sans', sans-serif;
}

.listFloatParagraph {
  margin-top: 1.1em;
  flex: 1 0 400px;
  font-family: 'Fira Sans', sans-serif;
  font-weight: 40;
}
